import Box from "@mui/material/Box";
import Paragraph from "src/components/Paragraph";
import { Link } from "gatsby-theme-material-ui";
import * as React from "react";
import { Helmet } from "react-helmet";
import Container from "src/components/Container";
import ImageHeaderUnit from "src/components/ImageHeaderUnit";
import ImageNavList from "src/components/ImageNavList";
import Layout from "src/components/Layout";
import SubHeader from "src/components/SubHeader";
import img from "src/images/what-we-do/community-programs.jpg";
import programsImg from "src/images/what-we-do/programs.jpg";

export default function CommunityBasedServicesPage() {
  return (
    <Layout>
      <Helmet>
        <title>Community-Based Services</title>
      </Helmet>
      <ImageHeaderUnit
        subtitle={
          <>
            <Link to="/what-we-do">What We Do</Link> &raquo; Community-Based
            Services
          </>
        }
        title="Assistance for a Secure and Safe Place to Live"
        img={img}
        maxTitleWidth={450}
      />
      <Container>
        <SubHeader>Our community-based programs at a glance</SubHeader>
        <Paragraph>
          In addition to our shelter and transitional housing programs,{" "}
          <b>
            EOCP assists individuals through the process of obtaining and
            retaining a secure and safe place to live.
          </b>
        </Paragraph>
        <Paragraph>
          We offer several pathways to people currently residing in the
          community at large, such as those living in public areas or in other
          environments not safe or secure for sustainable housing:
        </Paragraph>
        <Paragraph component="ul">
          <li>
            <b>Coordinated Entry System (CES)</b>; serving MediCal-eligible
            individuals through housing search and application processes,
            financial assistance for move-in, and supporting their ability to
            sustainably stay a tenant — in partnership with Alameda County’s CES
          </li>
          <li>
            <b>Oakland Together for Vets Collaborative</b>; supporting
            qualifying Veterans to search for, secure, and prevent the loss of
            housing — in partnership with the Supportive Services for Veterans
            and Families (SSVF) initiative at the Department of Veterans’
            Affairs (VA)
          </li>
          <li>
            <b>Family Rapid Re-Housing Program</b>; serving families with
            children, providing direct staff support for the housing search and
            move-in process, as well as rental assistance
          </li>
          <li>
            <b>Realignment Housing Program</b>; working with adults currently on
            probation to support them with finding and staying in housing
            placements — in partnership with Alameda County Probation Department
          </li>
        </Paragraph>

        <Box py={3} />

        <Box
          component="img"
          src={programsImg}
          sx={{ width: "50%", float: "left" }}
          mr={3}
          mb={2}
        />

        <SubHeader>Housing First model of care </SubHeader>

        <Paragraph>
          Through the nationally supported <i>Housing First</i> model of care,{" "}
          <b>
            we strive to assist individuals and families into housing without
            requiring that they first address behavioral health or income
            barriers.
          </b>{" "}
          EOCP staff prioritize placing each person in housing, as{" "}
          <b>
            our philosophy is that it is easier and more sustainable for a
            person to tackle such challenges once they are housed,
          </b>{" "}
          rather than while they are experiencing homelessness.
        </Paragraph>

        <Paragraph>
          Each process is tailored to an individual’s needs. Assistance can
          include:{" "}
        </Paragraph>
        <Box pl={3}>
          <Paragraph component="ul" sx={{ overflow: "hidden" }}>
            <li>Locating a safe and secure housing unit</li>
            <li>
              Financial support for move-in costs, overdue rent, or rent
              subsidies while securing the means to begin paying rent
            </li>
            <li>
              Providing referrals to mental health care or substance use
              treatment{" "}
            </li>
          </Paragraph>
        </Box>

        <Box sx={{ clear: "both" }} />
      </Container>

      <Box sx={{ backgroundColor: "green.50" }} py={4} mt={{ xs: 0, sm: 5 }}>
        <Container>
          <SubHeader>Learn more about our approach and our programs</SubHeader>
          <ImageNavList
            links={[
              "/what-we-do",
              "/why-we-do-this-work",
              "/residential-services",
            ]}
          />
        </Container>
      </Box>
    </Layout>
  );
}
